import { graphql } from 'gatsby';
import { useTitle, useMeta } from 'hooked-head';
import { useInView } from 'react-intersection-observer';
import { GatsbyImage as Image } from 'gatsby-plugin-image';
import React, { useEffect, useState } from 'react';

import Author from './Author';
import Columns from '../Columns';
import Headline from '../Headline';
import HTML from '../HTML';
import SoundcloudPlayer from '../SoundcloudPlayer';

import { cleanInterviewTitle } from '../../../lib/create-interviews';

import {
  excerpt as excerptStyle,
  excerptLargeMarginBottom,
  contentContainer,
  contentInnterContainer,
  htmllContentContainer,
  metaContainer,
  number as numberStyle,
  featuredImageContainer,
  featuredImage as featuredImageStyle,
  featuredImageContainerAbsolute,
  authorScreenLargeContainer,
  authorScreenSmallContainer,
  sticky,
} from './style';

export const fragment = graphql`
  fragment Interview on MarkdownRemark {
    frontmatter {
      title
      excerpt
      number
      soundcloudLink: soundcloud_link

      featuredImage: featured_image {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 800)
        }
      }

      smImageTwitter: sm_image {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 1200, height: 600)
        }
      }

      smImageFacebook: sm_image {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 1200, height: 628)
        }
      }
    }

    html
  }
`;

const Interview = ({
  html,
  frontmatter: {
    title,
    number,
    featuredImage,
    smImageTwitter,
    smImageFacebook,
    excerpt,
    soundcloudLink,
  },
  authors,
  site: {
    siteMetadata: { siteUrl },
  },
}) => {
  const { ref, inView } = useInView();
  const [scrollY, setScrollY] = useState(null);
  const isAudioInterview = !!soundcloudLink;
  const cleanTitle = cleanInterviewTitle(title);

  useEffect(() => {
    if (inView && typeof window !== 'undefined') {
      setScrollY(window.scrollY);
    }
  }, [inView]);

  useTitle(cleanTitle);

  useMeta({
    name: 'og:title',
    content: cleanTitle,
  });

  useMeta({
    name: 'twitter:title',
    content: cleanTitle,
  });

  useMeta({
    name: 'og:description',
    content: excerpt,
  });

  useMeta({
    name: 'twitter:description',
    content: excerpt,
  });

  useMeta({
    name: 'twitter:card',
    content: smImageTwitter ? 'summary_large_image' : 'summary',
  });

  if (smImageTwitter || smImageFacebook) {
    if (smImageTwitter) {
      const smImageSrcTwitter =
        smImageTwitter.childImageSharp.gatsbyImageData.images.sources[0].srcSet;

      useMeta({
        name: 'twitter:image',
        content: `${siteUrl}${smImageSrcTwitter}`,
      });
    }

    if (smImageFacebook) {
      const smImageSrcFacebook =
        smImageFacebook.childImageSharp.gatsbyImageData.images.sources[0]
          .srcSet;

      useMeta({
        name: 'og:image',
        content: `${siteUrl}${smImageSrcFacebook}`,
      });
    }
  }

  return (
    <Columns>
      <div css={metaContainer}>
        {featuredImage && (
          <div
            css={[
              featuredImageContainer,
              sticky,
              inView && featuredImageContainerAbsolute(scrollY),
            ]}
          >
            <Image
              css={featuredImageStyle}
              image={featuredImage.childImageSharp.gatsbyImageData}
            />
            <span css={numberStyle}>#{number}</span>
          </div>
        )}

        {!isAudioInterview && (
          <div css={[authorScreenLargeContainer]} ref={ref}>
            {authors.map((author) => (
              <Author {...author} />
            ))}
          </div>
        )}
      </div>

      <div css={contentContainer}>
        <article css={contentInnterContainer}>
          <Headline theme="h1">
            <span dangerouslySetInnerHTML={{ __html: title }} />
          </Headline>

          <p
            css={[excerptStyle, !isAudioInterview && excerptLargeMarginBottom]}
            dangerouslySetInnerHTML={{ __html: excerpt }}
          />

          {html && (
            <div css={htmllContentContainer}>
              <HTML html={html} />
            </div>
          )}
        </article>

        {isAudioInterview && <SoundcloudPlayer track={soundcloudLink} />}

        <div css={[!soundcloudLink && authorScreenSmallContainer]}>
          {authors.map((author) => (
            <Author {...author} />
          ))}
        </div>
      </div>
    </Columns>
  );
};

export default Interview;
