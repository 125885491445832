import { graphql } from 'gatsby';
import React from 'react';

import Interview from '../components/Interview';
import Layout from '../components/Layout';

import { Provider as ContextProvider } from '../context';

const InterviewTemplate = ({
  data: {
    interview,
    authors,
    colors: {
      frontmatter: { interview_colors: colors },
    },
    site,
  },
}) => {
  const interviewNumber = interview.frontmatter.number;

  const getInterviewColor = () => {
    const numOfColors = Object.keys(colors).length;
    let index = parseInt(interviewNumber, 10) - 1;

    return colors[index % numOfColors];
  };

  return (
    <ContextProvider value={{ colors, getInterviewColor, isInterview: true }}>
      <Layout
        menuPadded={
          interview.frontmatter.soundcloudLink ? 'transparent' : 'white'
        }
        burgerMenuTheme="white"
      >
        <Interview {...interview} authors={authors?.nodes} site={site} />
      </Layout>
    </ContextProvider>
  );
};

export default InterviewTemplate;

export const query = graphql`
  query InterviewPage($interviewID: String!, $authorIDs: [String!]!) {
    interview: markdownRemark(id: { eq: $interviewID }) {
      ...Interview

      frontmatter {
        number
        soundcloudLink: soundcloud_link
      }
    }

    authors: allMarkdownRemark(filter: { id: { in: $authorIDs } }) {
      nodes {
        ...Author
      }
    }

    colors: markdownRemark(
      fileAbsolutePath: { regex: "/settings/interview-colors.md$/" }
    ) {
      frontmatter {
        interview_colors
      }
    }

    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
