/* eslint-disable jsx-a11y/iframe-has-title */

import React, { useContext } from 'react';

import { container } from './soundCloudPlayer.style';

import Context from '../../context';

const SoundcloudPlayer = ({ track, ...props }) => {
  const { getInterviewColor } = useContext(Context);

  return (
    <div css={container} {...props}>
      <iframe
        width="100%"
        height="150"
        scrolling="no"
        frameBorder="no"
        allow="autoplay"
        src={`https://w.soundcloud.com/player/?url=${track}&amp;buying=false&sharing=false&amp;download=false&amp;show_artwork=false&amp;show_user=false&amp;color=${getInterviewColor().replace(
          '#',
          ''
        )}`}
      />
    </div>
  );
};

export default SoundcloudPlayer;
