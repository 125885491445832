import { css } from '@emotion/react';

import { bodySpacing, fluid, fonts, mq } from '../../style-tokens';

export const excerpt = css`
  align-self: flex-end;
  font-family: ${fonts.sans.family};
  font-size: ${fluid(1.25, 1.85)};
  font-weight: 700;
  line-height: 1.15;
  margin-bottom: 0;
  margin-top: ${bodySpacing};
  max-width: ${fluid(15, 18)};
`;

export const excerptLargeMarginBottom = css`
  margin-bottom: ${fluid(2, 4)};
`;

export const metaContainer = css`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  position: relative;
`;

export const contentContainer = css``;

export const contentInnterContainer = css`
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: ${bodySpacing};
`;

export const htmllContentContainer = css`
  padding-left: ${fluid(0, 6)};
`;

export const number = css`
  color: white;
  font-family: ${fonts.sans.family};
  font-size: ${fluid(5.9, 10.75)};
  font-weight: 700;
  left: 50%;
  letter-spacing: ${fluid(0.63, 0.5)};
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
`;

export const featuredImageContainer = css`
  height: 100vh;
  padding-bottom: ${bodySpacing};
  position: relative;
  width: 100%;
  will-change: top;
`;

export const featuredImage = css`
  height: 100%;
  object-fit: cover;
  width: 100%;
`;

export const featuredImageContainerAbsolute = (top) => css`
  @media ${mq.tablet} {
    position: absolute;
    top: ${top}px;
  }
`;

export const authorScreenLargeContainer = css`
  display: none;

  @media ${mq.tablet} {
    display: block;
    justify-self: flex-end;
    margin-top: auto;
    padding-top: ${fluid(6, 8)};
    width: 100%;
  }

  > * + * {
    margin-top: ${fluid(2, 4)};
  }
`;

export const sticky = css`
  @media ${mq.tablet} {
    position: sticky;
    top: ${bodySpacing};
  }
`;

export const authorScreenSmallContainer = css`
  display: block;
  padding-bottom: ${bodySpacing};
  padding-top: ${bodySpacing};

  @media ${mq.tablet} {
    display: none;
  }

  > * + * {
    margin-top: ${fluid(2, 4)};
  }
`;
