import { css } from '@emotion/react';

import { bodySpacing, fluid } from '../../../style-tokens';

export const container = css`
  color: white;
`;

export const portrait = css`
  height: auto;
  width: 50%;
`;

export const descriptionContainer = css`
  margin-top: ${bodySpacing};

  > p {
    font-size: ${fluid(1.25, 1.525)};
    font-weight: 500;
    letter-spacing: ${fluid(0.02, 0.035)};
    line-height: 1.25;
    margin-top: 0;

    @media (min-width: 2300px) {
      font-size: ${fluid(1.75, 2.25)};
    }
  }

  > p:last-of-type {
    margin-bottom: 0;
  }
`;
