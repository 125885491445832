import { graphql } from 'gatsby';
import { GatsbyImage as Image } from 'gatsby-plugin-image';
import React, { forwardRef } from 'react';

import HTML from '../../HTML';

import {
  container,
  portrait as portraitStyle,
  descriptionContainer,
} from './author.style';

export const fragment = graphql`
  fragment Author on MarkdownRemark {
    frontmatter {
      portrait {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 800)
        }
      }
    }

    html
  }
`;

const Author = forwardRef(({ frontmatter: { portrait }, html }, ref) => (
  <div ref={ref} css={container}>
    {portrait && (
      <Image
        css={portraitStyle}
        image={portrait.childImageSharp.gatsbyImageData}
      />
    )}

    <div css={descriptionContainer}>
      <HTML html={`${html}`} />
    </div>
  </div>
));

export default Author;
